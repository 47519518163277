import React from "react";

const East = () => {
  return (
    <>
      <div className="card">
        <div className="card-header">
          <p>From The East</p>
          <p>"Meet Upon the Level and Part Upon the Square"</p>
        </div>
        <div className="card-body">
          <p>
            As we approach the close of another year, I am filled with a
            profound sense of gratitude and reflection. December is a time to
            celebrate our achievements, honor our traditions and prepare for the
            opportunities that lie ahead.
          </p>
          <p>
            Our Lodge will be in the Prince George Christmas Parade, again this
            year on Saturday December 7, forming up at 2:00 pm and the Parade
            starts at 3:00 pm. We will form up at the county Police stations,
            Scott Park side, police parking lot. It will be great fun passing
            out all the candy. So come out and don't be shy.
          </p>
          <p>
            This month, we will hold a significant and solemn event, at our
            stated: the election of our new Master. This process is one of the
            most important responsibilities we carry as Masons, and it is
            crucial that we approach it with the seriousness and deliberation it
            deserves. The election of our Master not only shapes the future
            direction of our Lodge but also ensures that our traditions and
            values are upheld with integrity and commitment. I encourage all
            members to attend this meeting and participate actively. Your
            presence and voice are vital.
          </p>
          <p>
            We will also be closing the Lodge of Sorrow until the new Master and
            line open it.
          </p>
          <p>
            The get together time for passing out of the Christmas Poinsettias
            for the widows is to be determined by the new Master. So many widows
            of our fallen Brothers really appreciate this tradition.
          </p>
          <p>
            As we gather for these important events, let us be mindful of the
            values we hold dear: brotherly love, relief, and truth. Let us also
            embrace the spirit of the season, reflecting on the past year and
            looking forward with hope and determination.
          </p>
          <p>
            Again it has been an honor and a privilege to serve this Lodge in
            the East. I was able to find a picture of the first Master of Prince
            George #115, Samuel Heath Jones, circa 1850's His photo is at our
            lodge sign in desk. So check it out!
          </p>
          <p>
            Thank you all, for your continued dedication, participation and hard
            work. Many of our goals have been achieved this year with great
            success. Together, we will ensure that our Lodge remains a beacon of
            light and source of strength for all who seek it.
          </p>
          <p>Have a Merry Christmas and Happy New Year!</p>
        </div>
        <div className="card-footer">
          <p>William "Bill" Coyner</p>
          <p>Worshipful Master, PG Lodge #115</p>
        </div>
      </div>
    </>
  );
};

export default East;
